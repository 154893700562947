import React from 'react';
import classNames from 'classnames';

const errorClassName = '!border-error-500 focus:!border-error-500';
const Input = React.forwardRef(({ isError = false, ...props }, ref) => {
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        'custom-input',
        props?.className ? props?.className.split(' ') : '',
        { [errorClassName]: isError }
      )}
    />
  );
});

export default Input;
