import axios from "axios";
import { faker } from "@faker-js/faker";
import axiosInstance from "app/interceptors";
import config from "app/config";

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const getProposalListDetailCommitment = async (idProposal, signal) => {
  const {data} = await axiosInstance.get(`${config.SAFEGUARD_API_URL}/Commitment/list-detail/${idProposal}`, { signal });
  return data
}

export const getProposalListCommitment = async (idProposal, signal) => {
  const {data} = await axiosInstance.get(`${config.SAFEGUARD_API_URL}/Commitment/list/${idProposal}`, { signal });
  return data;
}

export const getProposalDetailCommitment = async (idProposal, signal) => {
  const {data} = await axiosInstance.get(`${config.SAFEGUARD_API_URL}/Commitment/detail/${idProposal}`, { signal });
  return data;
}

export const SubmitCommitment = async (payload) => {
  const {data} = await axiosInstance.post(`${config.SAFEGUARD_API_URL}/Commitment/Submit`, payload);
  return data;
}

export const DraftCommitment = async (payload) => {
  const {data} = await axiosInstance.post(`${config.SAFEGUARD_API_URL}/Commitment/draft`, payload);
  return data;
}