import React from "react";
import classNames from "classnames";

const TitleText = ({ children, className }) => {
  return (
    <p className={classNames("title-text", ...className.split(" "))}>
      {children}
    </p>
  );
};

export default TitleText;
