import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Fragment,
} from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

const defaultClassName =
  "bg-white border-[1px] border-dashed border-gray-200 rounded-xl py-4 px-6 min-h-[130px] flex items-center justify-center !mt-2 cursor-pointer";
const errorClassName = "!border-error-500";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  cursor: 'pointer',
};

const filePreview = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  cursor: 'pointer',
  backgroundColor: 'rgba(0,0,0,0.7)',
};

const CustomDropzoneForm = ({
  maxSize = 10,
  maxFiles = 1,
  acceptedFile = {
    "image/*": [],
    "application/pdf": [],
    "applicaiton/docx": [],
  },
  informationText = "PNG, JPG, PDF, Docx up to 10MB",
  name,
  onDropSuccess,
  hidePreview = false,
}) => {
  const { control, setValue, setError, clearErrors } = useFormContext();

  const [files, setFiles] = useState([]);

  const maxSizeFile = useMemo(() => maxSize * 1000000, [maxSize]);

  const maxSizeFileValidation = useCallback(
    (file) => {
      if (file.size > maxSizeFile) {
        setError(name, { type: 'manual', message: `File maksimal berukuran ${maxSize}MB` });
        return {
          code: "maxSizeFileValidation",
          message: `File maksimal berukuran ${maxSize}MB`,
        };
      }
    },
    [maxSizeFile, maxSize, name, setError]
  );

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (!fileRejections.length) {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
        
        const fileUploaded = maxFiles > 1 ? acceptedFiles : acceptedFiles[0];
        
        setValue(name, fileUploaded, { shouldValidate: true });
        clearErrors([name]);
        
        onDropSuccess(fileUploaded);
      } else {
        setFiles([]);
        if (maxFiles > 1) {
          setValue(name, acceptedFiles);
        } else {
          setValue(name, acceptedFiles[0]);
        }
      }
    },
    [name, setValue, clearErrors, maxFiles, onDropSuccess]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFile,
    maxFiles: maxFiles,
    multiple: maxFiles > 1,
    validator: maxSizeFileValidation,
  });

  const thumbs = files.map(file => file.type.includes("image") ? (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="preview"
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
          onClick={() => window.open(file.preview, '_blank')}
        />
      </div>
    </div>
  ) : (
    <div
      key={file.name}
      style={filePreview}
      onClick={() => window.open(file.preview, '_blank')}
    >
      <AiOutlineEye className="text-white cursor-pointer" size={20} />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <Fragment>
            <div
              className={classNames(...defaultClassName.split(" "), {
                [errorClassName]: invalid,
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? null : (
                <div className="flex flex-col items-center space-y-1">
                  <FiUploadCloud className="text-gray-600" size={24} />
                  <p className="text-gray-600 text-sm !mt-3">
                    <b className="text-primary-700">Klik untuk upload</b> atau
                    seret dan lepas kesini
                  </p>
                  <p className="text-gray-600 text-sm">{informationText}</p>
                </div>
              )}
            </div>
            {!hidePreview && (
              <aside style={thumbsContainer}>
                {thumbs}
              </aside>
            )}
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default CustomDropzoneForm;
