import { AuthConsumer } from "providers/authProviders";
import React from "react";
import { CgSpinner } from "react-icons/cg";

export const SignOutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return (
        <div className="relative min-h-screen w-screen flex justify-center items-center">
          <CgSpinner className="animate-spin" />
        </div>
      );
    }}
  </AuthConsumer>
);
