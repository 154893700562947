import React, { useMemo, useCallback, useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';

let NEXT_PAGE = null;

const CustomSelectForm = ({ placeholder = 'Cari ...', ...props }) => {
  const { name } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <ReactSelect
            {...props}
            classNamePrefix="my-select"
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(val);
              if (props?.onChange) props.onChange(val);
            }}
            onBlur={onBlur}
            styles={{
              placeholder: (styles) => ({
                ...styles,
                color: 'rgb(71 84 103)',
                fontSize: 12,
                fontWeight: 400,
              }),
              control: (styles) => ({
                ...styles,
                borderColor: invalid ? '#F04438' : '#EAECF0',
                ':hover': {
                  ...styles[':hover'],
                  borderColor: invalid ? '#F04438' : '#EAECF0',
                },
                boxShadow: 'none',
                borderRadius: '6px',
              }),
              singleValue: (styles) => ({
                ...styles,
                color: 'rgb(71 84 103)',
              }),
              indicatorContainer: (styles, { isFocused }) => ({
                ...styles,
                color: isFocused ? 'rgb(71 84 103)' : 'rgb(71 84 103)',
                ':hover': {
                  ...styles[':hover'],
                  color: 'rgb(71 84 103)',
                },
              }),
              option: (
                styles,
                { data, isSelected, isFocused, isDisabled }
              ) => ({
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'auto',
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? '#01A24A'
                  : isFocused
                  ? '#EEFFF4'
                  : undefined,
                color: isDisabled
                  ? 'hsl(0, 0%, 80%)'
                  : isSelected
                  ? '#fff'
                  : isFocused
                  ? 'rgb(71 84 103)'
                  : 'rgb(71 84 103)',
              }),
            }}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">
              {error?.message || error?.value?.message}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(CustomSelectForm);
