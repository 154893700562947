import { useMutation, useQuery } from "@tanstack/react-query";
import { FINALIZATION } from "services";

export const useCommitmentFinalizationDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return FINALIZATION.getFinalizationDetailCommitment(id, signal);
    },
    ...options
  });
};

export const usePostCommitmentFinalization = () => {
  return useMutation((payload) => FINALIZATION.SubmitCommitmentFinalization(payload))
}