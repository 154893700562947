import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";

const defaultClass =
  "tab tab-bordered text-sm text-semibold text-gray-500 border-transparent min-w-max";
const activeClass =
  "tab-active bg-primary-50 !text-primary-700 !border-b-[2px] !border-primary-700";

const Tabs = forwardRef(({ items, onTabChanged }, ref) => {
  const [activeTab, setActiveTab] = useState(0);

  useImperativeHandle(
    ref,
    () => {
      return {
        setTabIndex: (idx) => {
          setActiveTab(idx)
        },
      };
    },
    []
  );
  return (
    <div className="tabs border-b-[1px] after:border-gray-300 scrollbar flex-nowrap overflow-x-auto clear-both">
      {items.map((item, key, clickable) => (
        <span
          key={key}
          className={classNames(...defaultClass.split(" "), {
            [activeClass]: activeTab === key,
          })}
          onClick={() => {
            if (onTabChanged) {
              setActiveTab(key);
              onTabChanged(item.id);
            }
          }}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
});

export default React.memo(Tabs);
