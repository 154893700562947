import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const InputFormRadio = ({
  controllerName,
  className = '',
  label,
  required = false,
  textArea = false,
  usingGrid = false,
  gridClassName = '',
  values = [],
  directionColumn = false,
  isCustomOption = false,
  onGetValueOtherOptions = () => {},
  customOption,
  showErrorLabel = true,
  labelClass = '',
  optionClass,
  injectedValue = null,
  ...props
}) => {
  const { control } = useFormContext();

  const [customValues, setCustomValues] = useState(customOption);

  console.log('prsp', props.disabled);

  useEffect(() => {
    setCustomValues(customOption);
  }, [customOption]);

  const handleCustomInputChange = (text) => {
    setCustomValues(text);
    onGetValueOtherOptions(text);
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className={`form-control w-full ${className}`}>
          <label className={`font-semibold text-[14px] ${labelClass}`}>
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          {usingGrid ? (
            <div className={gridClassName || `grid grid-cols-3 gap-4 mt-2`}>
              {values.map((data, index) => (
                <label
                  key={data.value}
                  className={`${
                    directionColumn ? 'mt-2' : ''
                  } flex items-center flex-1 ${
                    invalid ? 'border-[#F04438]' : ''
                  } ${
                    value === data.value ? 'bg-[#EEFFF4]' : 'bg-white'
                  }  border py-2 px-4 rounded-lg gap-4 ${optionClass}`}
                >
                  <input
                    type="radio"
                    name={controllerName}
                    {...props}
                    onChange={() => onChange(data.value)}
                    checked={
                      injectedValue !== null
                        ? injectedValue?.value === data?.value
                        : value === data?.value
                    }
                    className="absolute opacity-0 w-0 h-0"
                  />
                  <div
                    className="radio-custom"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '18px',
                      minHeight: '18px',
                      borderRadius: '50%',
                      border: invalid
                        ? '2px solid #F04438'
                        : value === data.value
                        ? '2px solid #01A24A'
                        : '1px solid #D0D5DD',
                      backgroundColor: value === data.value ? '#FFFFFF' : '',
                    }}
                  >
                    {value === data.value && (
                      <div
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: '#01A24A',
                        }}
                      />
                    )}
                  </div>
                  {data.label}
                </label>
              ))}
            </div>
          ) : (
            <div
              className={`${
                directionColumn ? 'flex-column' : 'flex'
              } flex-wrap gap-4 mt-2`}
            >
              {values.map((data, index) => {
                return (
                  <label
                    key={data.value}
                    className={`${
                      directionColumn ? 'mt-2' : ''
                    } flex items-center flex-1 ${
                      invalid ? 'border-[#F04438]' : ''
                    } ${props.disabled && 'cursor-not-allowed bg-[#efefef]'} ${
                      value === data.value
                        ? `border border-[#01A24A] bg-[#EEFFF4] ${
                            props.disabled && ' !bg-gray-200 !border-gray-400'
                          }`
                        : 'bg-white border border-grey'
                    } py-2 px-4 rounded-lg gap-4`}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <input
                      type="radio"
                      name={controllerName}
                      {...props}
                      onChange={() => onChange(data.value)}
                      checked={
                        injectedValue !== null
                          ? injectedValue?.value === data?.value
                          : value === data?.value
                      }
                      className={`absolute opacity-0 w-0 h-0 ${
                        props.disabled && 'cursor-not-allowed'
                      }`}
                    />

                    <div
                      className={`radio-custom ${
                        props.disabled && '!border-2 !border-gray-400'
                      }`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '18px',
                        minHeight: '18px',
                        borderRadius: '50%',
                        border: invalid
                          ? '2px solid #F04438'
                          : value === data.value
                          ? '2px solid #01A24A'
                          : '1px solid #D0D5DD',
                        backgroundColor: value === data.value ? '#FFFFFF' : '',
                      }}
                    >
                      {value === data.value ? (
                        <div
                          className={`${props.disabled && '!bg-gray-400'}`}
                          style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#01A24A',
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    {data.label !== 'Custom' ? (
                      data.label
                    ) : (
                      <input
                        value={
                          customValues === '' ? customOption : customValues
                        }
                        onChange={(e) => {
                          handleCustomInputChange(e.target.value);
                        }}
                        placeholder="Nominal lain"
                      ></input>
                    )}
                  </label>
                );
              })}
            </div>
          )}

          {invalid && showErrorLabel && (
            <label>
              <div className="text-xs text-error-600 mt-2 ml-1 label-text-alt text-error">
                {error?.message}
              </div>
            </label>
          )}
        </div>
      )}
    />
  );
};

export default InputFormRadio;
