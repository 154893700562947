import axios from 'axios';
import { faker } from '@faker-js/faker';
import axiosInstance from 'app/interceptors';
import config from 'app/config';

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const getProposalList = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.SAFEGUARD_API_URL}/Proposal/datatable`,
    params
  );
  return data;
};

export const getDetailProposal = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalDocs = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/docs/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalInformation = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/information/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalFilterNegativeList = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/filter-negative-list/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalInitiator = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/initiator/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalRiskClarification = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/risk-clasification/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalScreening = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/screening/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalMitigation = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/risk-mitigation/${id}`,
    { signal }
  );
  return data;
};

export const getDetailProposalCommitment = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.SAFEGUARD_API_URL}/Proposal/commitment/${id}`,
    { signal }
  );
  return data;
};

export const getCFPList = (params) => {
  return new Promise((resolve, reject) => {
    const datas = Array.from({ length: 35 }).map((x) => ({
      id: faker.datatype.uuid(),
      submission_date: faker.datatype.datetime(),
      proposal: faker.company.companyName(),
      project: faker.commerce.productName(),
      applicant: faker.name.fullName(),
      status: faker.helpers.arrayElement([
        'Perlu Diverifikasi',
        'Sedang Diverifikasi',
        'Telah Diverifikasi',
        'Tolak Verifikasi',
        'Disetujui',
        'Ditolak',
      ]),
    }));
    let newData = paginate(datas, params.limit, params.page);
    const pageCount = Math.round(Math.ceil(datas.length / params.limit));

    const pagination = {
      total: datas.length,
      limit: params.limit,
      page: params.page,
      previous_pages: params.page - 1 === 0 ? null : params.page - 1,
      next_pages: params.page + 1 > pageCount ? null : params.page + 1,
    };
    const newObject = {
      result: newData,
      pagination,
    };
    setTimeout(() => {
      resolve(newObject);
    }, 2000);
  });
};

export const verify = async (path, payload) => {
  const { data } = await axiosInstance.post(
    `${config.SAFEGUARD_API_URL}/Verify/${path}`,
    payload
  );
  return data;
};
// export const getHandoverList = async (params) => {
//   const {data} = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/perhimpunan-dana/v1/bank-account`, {params});
//   return data
// };
