import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

const Accordion = ({
  children,
  theme = "",
  open = false,
  title = () => <></>,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const themeOption = useMemo(() => {
    let bgColor;
    switch (theme) {
      case "deep_green":
        bgColor = "bg-[#E1F1D6] text-[#01A24A]";
        break;
      case "green":
        bgColor = "bg-[#B0FFD3] text-[#01A24A]";
        break;
      case "yellow":
        bgColor = "bg-[#E4ECAA] text-[#01A24A]";
        break;
      case "purple":
        bgColor = "bg-[#D9D6FE]";
        break;
      case "orange":
        bgColor = "bg-[#FBD3AD] text-[#ff8a1c]";
        break;
      case "blue":
        bgColor = "bg-[#B9E6FE]";
        break;
      default:
        bgColor = "bg-white border border-gray-200";
        break;
    }

    return bgColor;
  }, [theme]);

  return (
    <motion.div>
      <div
        className={classNames(
          "px-5 py-4 rounded-t-md flex justify-between items-center",
          themeOption
        )}
      >
        <div className="text-justify pr-5 w-full">{title()}</div>
        <span
          className="cursor-pointer font-bold"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          {isOpen ? "Tutup" : "Buka"}
        </span>
      </div>
      {isOpen && (
        <motion.div
          initial={{ y: "-5%", opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.1,
            },
          }}
          exit={{ y: "-5%", opacity: 0 }}
          className={classNames("bg-white border border-gray-200 w-full p-5")}
        >
          {children}
        </motion.div>
      )}
    </motion.div>
  );
};

export default Accordion;
