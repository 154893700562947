import { BasicLayout } from "components";
import _ from "lodash";
import NotFound from "pages/errors/404";
import { useLayoutEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as WrapperRoutes,
  useLocation,
} from "react-router-dom";
import route from "./route";
import { SignInCallback } from "components/auth/signInCallback";
import { SignOutCallback } from "components/auth/signOutCallback";
import { SilentRenew } from "components/auth/silentRenew";
import Logout from "components/auth/logout";
import { AuthConsumer } from "providers/authProviders";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Routes = () => {
  // const { token } = useSelector((state) => state.auth);
  return (
    <Router>
      <ScrollToTop>
        <WrapperRoutes>
          <Route path="/signin-oidc" element={<SignInCallback />} />
          <Route path="/signout-oidc" element={<SignOutCallback />} />
          <Route path="/silentrenew" component={<SilentRenew />} />
          <Route path="/logout" element={<Logout />} />
          {_.map(_.filter(route, { routeType: "public" }), (val, key) => {
            const Component = val.element;
            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={
                  <BasicLayout>
                    <Component />
                  </BasicLayout>
                }
              />
            );
          })}
          {_.map(_.filter(route, { routeType: "private" }), (val, key) => {
            const Component = val.element;

            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={
                  <AuthConsumer>
                    {({ isAuthenticated, signinRedirect }) => {
                      if (isAuthenticated()) {
                        return (
                          <BasicLayout
                            props={val.sidebarProps}
                            sidebar={val.sidebar}
                          >
                            <Component />
                          </BasicLayout>
                        );
                      } else {
                        return signinRedirect();
                      }
                    }}
                  </AuthConsumer>
                }
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </WrapperRoutes>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
