import { RiShieldKeyholeLine, RiBookReadLine } from 'react-icons/ri';
import { TbReportAnalytics } from 'react-icons/tb';

const MENU_ITEMS = [
  {
    icon: <RiShieldKeyholeLine className="text-white w-[18px] h-[20px]" />,
    id: 'safeguard',
    label: 'Daftar Prinsip',
    path: '/safeguard',
  },
  {
    icon: <RiBookReadLine className="text-white w-[18px] h-[20px]" />,
    id: 'CFP',
    label: 'Daftar Full Proposal',
    path: '/cfp',
  },
  {
    icon: <TbReportAnalytics className="text-white w-[18px] h-[20px]" />,
    id: 'commitment',
    label: 'Laporan Komitmen',
    path: '/commitment',
  },
];

export default MENU_ITEMS;
