import classNames from "classnames";
import Input from "components/atoms/Input";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const InputForm = ({ name, wrapperClass = "", ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={props.defaultValue??""}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className={classNames("w-full", wrapperClass)}>
          <Input
            {...props}
            value={value}
            onChange={onChange}
            isError={invalid}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default InputForm;
