import config from 'app/config';
import axiosInstance from 'app/interceptors';
import axios from 'axios';

export const getMitigationList = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.MASTER_API_URL}/v1/Isp/list`,
    { signal }
  );
  return data;
};

export const getNegativeList = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.MASTER_API_URL}/v1/DaftarNegatif/list`,
    { signal }
  );
  return data;
};

export const getSafeguardList = async (params, signal) => {
  const { data } = await axiosInstance.post(
    `${config.MASTER_API_URL}/v1/Isp/datatable`,
    params
  );
  return data;
};

export const postSafeguard = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.MASTER_API_URL}/v1/Isp`,
    params
  );
  return data;
};

export const deleteSafeGuard = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.MASTER_API_URL}/v1/Isp`,
    { params: { ...params } }
  );
  return data;
};

export const getSafeguard = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.MASTER_API_URL}/v1/Isp/${id}`
  );
  return data;
};

export const putSafeguard = async (payload) => {
  const { data } = await axiosInstance.put(
    `${config.MASTER_API_URL}/v1/Isp`,
    payload
  );

  return data;
};
