import { AuthConsumer } from "providers/authProviders";
import React from "react";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <span>loading...</span>;
    }}
  </AuthConsumer>
);
